// import React, { ReactNode } from 'react';
import React from "react"
import PropTypes from "prop-types"

import * as style from "./affiliates.module.scss"


const Affiliate = ({logo, name, url}) => (
  
<div className={style.affiliate}>

<a href={'https://' + url} target="_blank" rel="noreferrer">

 
  {logo && (
        <img className={style.affiliate__logo} src={logo} alt={name} />        
    )}
  {name && (
    <div className={style.affiliate__name}>
        {name}
    </div>
    )}
  {/* {url && (
    <div className="affiliate__url">
        {url}
    </div>
    )} */}

</a>
</div>


)






Affiliate.defaultProps = {
    logo: ``,
    name: ``,
    url: ``,
  }
  
  Affiliate.propTypes = {
    logo: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }


export default Affiliate
