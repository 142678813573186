import React from "react"

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import { graphql } from 'gatsby'

import Affiliate from "../../components/affiliates/affiliates"



const Contact = ({ data }) => (

  <Layout>
    
    <Seo title="Contact" bodyClass='st' />

    <div className="column column--sidebar alignright">
      <div className="sticky">
        <h1 className={style.titleLowercase}>Contact</h1>

        <p><strong>Specyal T </strong><br/>
        <small>Pro Musician / Songwriter <br/> Executive Producer</small></p>

        <p><strong>email:</strong><br/>
        <a href="mailto:specyalt@specyaltproductions.com">specyalt@specyaltproductions.com</a></p>

        <p><strong>phone:</strong><br/>
        647.273.2327</p>

        <hr/>

        <p><strong>For All Booking Inquiries:</strong><br />
        <a href="mailto:bookings@specyaltproductions.com">bookings@specyaltproductions.com </a></p>
      </div>

    </div>

    <div className="column column--content">



      <span className={style.subtitleLowercase}>Affiliates</span>


      <div className="grid grid--third">

        <Affiliate
          url="specyaltproductions.com/stp"
          name="S.T.P"
          logo={data.logo_stp.publicURL}
          />
        <Affiliate
          url="tma149.ca/frm_display/tma149-members/entry/20146/"
          name="TMA"
          logo={data.logo_tma.publicURL}
          />
        <Affiliate
          url="socan.ca"
          name="A Member Of Socan"
          logo={data.logo_socan.publicURL}
          />
        <Affiliate
          url="soundcloud.com/massivesoundcanada"
          name="Massive Sound Studios"
          logo={data.logo_massive.publicURL}
          />
        <Affiliate
          url="songwriters.ca"
          name="SAC"
          logo={data.logo_sac.publicURL}
          />

        <Affiliate
          url="hyperemesis.org"
          name="HER Foundation"
          logo={data.logo_her.publicURL}
          />
      </div>

      <hr/>

      <span className={style.subtitleLowercase}>Visit these other SpecyalT sites</span>

      <div className="grid grid--third">

        <Affiliate
          url="instagram.com/iamspecyal"
          name="Instagram"
          logo={data.logo_instagram.publicURL}
          />
        <Affiliate
          url="facebook.com/iamspecyal"
          name="Facebook"
          logo={data.logo_facebook.publicURL}
          />
        <Affiliate
          url="youtube.com/specyalt"
          name="YouTube"
          logo={data.logo_youtube.publicURL}
          />
        <Affiliate
          url="soundclick.com/specyalt"
          name="Soundclick"
          logo={data.logo_soundclick.publicURL}
          />
        <Affiliate
          url="twitter.com/SpecyalT"
          name="Twitter"
          logo={data.logo_twitter.publicURL}
          />
        <Affiliate
          url="itunes.apple.com/ca/artist/specyal-t/id304663623?uo=4"
          name="iTunes"
          logo={data.logo_itunes.publicURL}
          />

      </div>



	
    </div>

  </Layout>
)

export default Contact



export const query = graphql`
  query {
    logo_her: file(relativePath: {eq: "st-affiliates/affiliate-her_foundation.jpg"}) {
      publicURL
    }
    logo_tma: file(relativePath: { eq: "st-affiliates/affiliate-tma.jpg" }) {
      publicURL
    }
    logo_socan: file(relativePath: {eq: "st-affiliates/affiliate-socan.jpg"}) {
      publicURL
    }
    logo_massive: file(relativePath: { eq: "st-affiliates/affiliate-massive.jpg" }) {
      publicURL
    }
    logo_sac: file(relativePath: {eq: "st-affiliates/affiliate-sac.jpg"}) {
      publicURL
    }
    logo_stp: file(relativePath: { eq: "st-affiliates/affiliate-stp.jpg" }) {
      publicURL
    }
    logo_instagram: file(relativePath: {eq: "links/instagram.svg"}) {
      publicURL
    }
    logo_facebook: file(relativePath: { eq: "links/facebook.svg" }) {
      publicURL
    }
    logo_youtube: file(relativePath: {eq: "links/youtube.svg"}) {
      publicURL
    }
    logo_soundclick: file(relativePath: { eq: "links/soundclick.svg" }) {
      publicURL
    }
    logo_twitter: file(relativePath: {eq: "links/twitter.svg"}) {
      publicURL
    }
    logo_itunes: file(relativePath: { eq: "links/itunes.svg" }) {
      publicURL
    }
  }
`

